var globalfunctions = {
	/*brandsCarousel: function(){
		if( $('.brandsCarousel').length > 0 ){
			$('.brandsCarousel').slick({
			  dots: true,
			  infinite: false,
			  speed: 500,
			  slidesToShow: 5,
			  slidesToScroll: 3,
			  autoplay: true,
		      autoplaySpeed: 2000,
		      arrows: false,
		      infinite: true
			});
		}
	},*/
	resetJs: function(){
		//Reset Text Editor Styles
		$('p').attr('style','');
		$('a').attr('style','');
		$('img').attr('style','');
	},
	servicesCarousel: function(){
		if( $('#servicesCarousel').length > 0 ){
			$('#servicesCarousel').slick({
			  dots: true,
			  infinite: false,
			  speed: 500,
			  slidesToShow: 3,
			  slidesToScroll: 1,
			  autoplay: true,
		      autoplaySpeed: 4000,
		      arrows: false,
		      infinite: true,
		      responsive: [
		          {
		            breakpoint: 991,
		            settings: {
		              slidesToShow: 2
		            }
		          },
		          {
		            breakpoint: 575,
		            settings: {
		              slidesToShow: 1
		            }
		          }
		        ]
			});
		}
	},
	videosCarousel: function(){
		if( $('.videosCarousel').length > 0 ){
			$('.videosCarousel').slick({
			  dots: true,
			  infinite: false,
			  speed: 500,
			  slidesToShow: 3,
			  slidesToScroll: 1,
			  autoplay: true,
		      autoplaySpeed: 4000,
		      arrows: false,
		      infinite: true,
		      responsive: [
		          {
		            breakpoint: 991,
		            settings: {
		              slidesToShow: 2
		            }
		          },
		          {
		            breakpoint: 575,
		            settings: {
		              slidesToShow: 1
		            }
		          }
		        ]
			});
		}
	},
	eventsCarousel: function(){
		if( $('.eventsCarousel').length > 0 ){
			$('.eventsCarousel').slick({
			  dots: true,
			  infinite: false,
			  speed: 500,
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  autoplay: true,
		      autoplaySpeed: 6000,
		      arrows: false,
		      infinite: true
			});
		}
	},
	videoPopup: function(){
		if( $('.popup-youtube, .popup-vimeo, .popup-gmaps').length > 0 ){
			$('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
				disableOn: 700,
				type: 'iframe',
				mainClass: 'mfp-fade',
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false
			});
		}
	},
	eventsForm: function(){
		if($('.popup-with-form').length > 0){
			$('.popup-with-form').magnificPopup({
				type: 'inline',
				preloader: false,
				focus: '#name',
				// When elemened is focused, some mobile browsers in some cases zoom in
				// It looks not nice, so we disable it:
				callbacks: {
					beforeOpen: function() {
						if($(window).width() < 700) {
							this.st.focus = false;
						} else {
							this.st.focus = '#name';
						}
					}
				}
			});
			$('.popup-with-form').click(function() {
				var title = $(this).closest('.card-body').find(".card-title").text();
				var description = $(this).closest('.card-body').find(".card-text").text();
				$("#event_submission_form").find("#program").val(title);
				$("#event_submission_form").find(".event-change-code").html(title);
				$("#event_submission_form").find(".event-change-p").html(description);
			});

			var form = $('#event_submission_form');

			$(form).submit(function(event) {
			    event.preventDefault();
			    var formData = $(form).serialize();
			    $.ajax({
			        type: 'POST',
			        url: $(form).attr('action'),
			        data: formData,
			        xhr: function() {
			            var xhr = $.ajaxSettings.xhr();
			            xhr.upload.onprogress = function(e) {
			                $('.progressingOverlay').fadeIn(400);
			            };
			            return xhr;
			        }
			    }).done(function(response) {
			    	$.magnificPopup.close();
			        $('.progressingOverlay').fadeOut(400);
			        $('input, textarea').val('');
			        swal("Başarılı", "Katılım isteğiniz başarıyla alındı", "success");
			    }).fail(function(data) {
			    	$.magnificPopup.close();
			        swal("Hata", "Form Gönderilirken Bir Hata Oluştu", "error");
			    });
			});

		}
		
	},
	all: function(){
		/*globalfunctions.brandsCarousel();*/
		globalfunctions.servicesCarousel();
		globalfunctions.videosCarousel();
		globalfunctions.eventsCarousel();
		globalfunctions.videoPopup();
		globalfunctions.eventsForm();
		globalfunctions.resetJs();
	}
}

jQuery(document).ready(function($) {
	globalfunctions.all();
});